import {getLink} from '../linker'

const formatters = {
  // Show Customer link
  customerGridLink: (cellVal, options, rowData) => {
    const ident = rowData.id
    let href = getLink('customer', ident)
    return `<a class="showLink" data-id="${ident}" href="${href}" >${cellVal}</a>`
  },

}

export default formatters
