
/**
 * Holder 'statics' for $ refs
 */
import { _get } from '../objectz'
import { isEmpty } from '../utils/is'

const schemaRefs = {
  refs:{},

  get(key){
    return _get(schemaRefs.refs, key)
  },

  isEmpty(){
    return isEmpty(schemaRefs.refs)
  }
}

export default schemaRefs
