import RestDataService from '@yakit/core/api/RestDataService'

/**
 * Customer Rest Api additions
 */
export default function(opts){

  const batchApi = RestDataService({ ...opts, key: 'autocash/batch' })

  batchApi.rpc = async (name, data) => {
    const res = batchApi.fetch({method: 'post', path: `rpc?op=${name}`, json: data}).json()
    return res
  }

  /**
   * Call and import with reference to files that have been uploaded to tempDir
   */
  batchApi.importFile = async (data) => {
    const res = batchApi.fetch({method: 'post', path: `rpc?op=importFile`, json: data}).json()
    return res
  }

  const search = batchApi.search
  //ts-ignore
  batchApi.search = (params) => {
    if (params.q.lockbox) {
      const q = {...params.q }
      q.lockbox = q.lockbox.map(l => l.num)
      params.q = q
    }
    return search(params)
  }

  return batchApi
}

