import KY  from 'ky'
// import KY  from 'ky-universal'
import type { KyInstance } from 'ky/distribution/types/ky'
import type { Options, ResponsePromise } from 'ky';

export {KyInstance, Options, ResponsePromise}

// subscriber's handlers, allows to add subs without needing to create a new ky
const subs = {
  before: [],
  after: []
}

const defaults = {
  prefixUrl: '/api',
  timeout: 60000, //we have some long running processes so set to 1 minute
  credentials: 'include',
  hooks: {
    beforeRequest: [
      request => { subs.before.forEach(sub => sub(request)) }
    ],
    afterResponse: [
			(_request, _options, response) => { subs.after.forEach(sub => sub(_request, _options, response)) }
    ]
  },
  //TODO look into this further, for now we turn off any caching
  // might be better way? https://stackoverflow.com/a/42518434
  headers: {
		'Cache-Control': 'no-cache',
    'Pragma': 'no-cache'
	}
}

let kyInstance: KyInstance //kyInstance holder

function getKy(): KyInstance { return kyInstance}

function makeKy({ apiConfig }){

  if(apiConfig.enableAuthHeader){
    KyFactory.enableAuthHeader()
  }
  KyFactory.defaults.prefixUrl = apiConfig.apiUrl
  return KyFactory.build()
}

const KyFactory = {
  defaults,

  /**
   * the current ky instance
   * @returns {KyInstance} the instance
   */
  getKy,

  /**
   * builds the default ky with any extra overide options passed in
   * shouldn't need to do this more than once really, and then KyFactory.getKy can be used
   *
   * @param {Object} opts extra options
   * @returns {KyInstance} the instance
   */
  build(opts?: any): KyInstance{
    kyInstance = KY.create({...this.defaults, ...opts})
    return kyInstance
  },

  /**
   * can subscribe to ky hooks and not need to recreate a ky to have them fired
   * Used for loading indicators and logging
   *
   * @param {string} type before or after
   * @param {function} handler funtion, see ky for what it can expect depending on the before or after
   * @returns {function} a function to call to unsub to avoid mem leaks
   */
  subscribe(type, handler) {
    subs[type] = [...subs[type], handler] // add handler to the array of subscribers
    return () => subs[type] = subs[type].filter(sub => sub !== handler)   // return unsubscribe function
  },

  /**
   * opinionated way to add Bearer token header. add a beforeRequest sub to add the header
   */
  enableAuthHeader() {
    const authHandler = request => {
      if (request.url.indexOf('login') > -1) return
      request.headers.set('Authorization', 'Bearer ' + localStorage.getItem('bearer'))
    }
    this.subscribe('before', authHandler)
  }
}

export { KyFactory, getKy, makeKy }

