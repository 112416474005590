import { isString, isFalsy } from './is'
import dayjs from './dayz'
import money from './money'

const numberFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

const percentFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2
})

/**
 * checks if falsy and checks string for nil values like 'null', 'undefined`
 */
function isZeroish(val) {
  if (isFalsy(val)) return true
  if (isString(val)) {
    if (val === 'undefined' || val === 'null' || val.trim() === '') {
      return true
    }
  }
  return false
}

/**
 * standard 2 digit amount format with no currency indicator
 * If its falsy then it defaults to 0
 * 1000 -> 1,000.00
 * 1.2 -> 1.20
 * undefined -> 0
 */
export function formatNumber(val) {
  if (isZeroish(val)) return '0'

  if (isString(val)) {
    //replace comma, convert to number with +
    return numberFormatter.format(+val.replace(/,/g, ''))
  } else {
    return numberFormatter.format(val)
  }
}

const o = {
  // TODO this is basically the same as money. do we need it?
  number(val) {
    if (isZeroish(val)) return '0'
    if (isString(val)) {
      //replace comma, convert to number with +
      let num = +val.replace(/,/g, '')
      return numberFormatter.format(num)
    } else {
      return numberFormatter.format(val)
    }
  },
  percent(val) {
    if (isZeroish(val)) return '0'
    if (isString(val)) {
      //replace comma, convert to number with +
      let num = +val.replace(/,/g, '')
      return percentFormatter.format(num * 100)
    } else {
      return percentFormatter.format(val * 100)
    }
  },
  integer(val) {
    return Number.isNaN(val) ? val : parseInt(val, 10)
  },
  date(val) {
    return val ? dayjs(val).format('MM/DD/YYYY') : ''
  },
  datetime(val) {
    return val ? dayjs(val).format('MM/DD/YYYY h:mm A') : ''
  },
  year(val){
    return val ? dayjs(val).format('YYYY') : ''
  },
  momentAgo(val) {
    return dayjs(val).fromNow()
  },
  money(val) {
    return money(val).format()
  },
  moneyOrZero(val) {
    //FIXME hack to keep it compatibile for now, not sure that we need this, we should look at removing this.
    if (isZeroish(val)) return '0'
    return money(val).format()
  },
  moneyAbs: (val) => money(val).abs().format(),
  moneyAbs$: (val) => money(val).abs().format$(),
  money$: (val) => money(val).format$(),
  //aliases for json-schema lookup. no camel case and hyphen seperators
  'date-time': (val) => o.datetime(val),
  'money-abs': (val) => o.moneyAbs(val),
  'money-abs$': (val) => o.moneyAbs$(val),

  limitLength(val, length = 10) {
    if (!val) return ''
    if (val.length <= length) {
      return val
    }
    return val.substring(0, length - 3) + '...'
  }
}

export function addFormatters(newFormatters) {
  for (const key in newFormatters) {
    o[key] = newFormatters[key]
  }
}

/**
 * formatts based on passed in type that will try and match funtion name
 */
export function formatLookup(func, val) {
  let fmatter = o[func]
  return fmatter ? fmatter(val) : val
}

export default o
