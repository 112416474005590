<script>
  import { Navbar} from 'framework7-svelte';
  import NavRight from './NavRight.svelte'

  export let title = undefined
</script>

<Navbar large transparent {title} titleLarge={title} backLink="Back">
  <NavRight/>
</Navbar>

