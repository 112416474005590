/**
 * Custom Setup for notyf, we call it Growl
 */
import { Notyf } from 'notyf'


const notyf = new Notyf({
  duration: 2000,
  dismissible: true,
  position: { x: 'right', y: 'top' },
  types: [
    {
      type: 'success',
      background: 'rgb(var(--color-primary-dark-rgb), 0.95)',
      // duration: 0,
      icon: {
        className: 'material-icons md-24',
        tagName: 'span',
        color: 'var(--color-success)',
        text: 'thumb_up'
      }
    },
    {
      type: 'error',
      background: 'rgb(var(--color-danger-rgb), 0.95)',
      duration: 0,
      dismissible: true,
      icon: {
        className: 'material-icons md-36',
        tagName: 'span',
        color: 'white',
        text: 'report'
      }
    },
    {
      type: 'warn',
      background: 'rgb(var(--color-black-rgb), 0.95)',
      className: 'notyf__toast--warn',
      duration: 0,
      icon: {
        className: 'material-icons md-36',
        tagName: 'i',
        color: 'var(--color-warning)',
        text: 'warning'
      }
    },
    {
      type: 'info',
      background: 'rgb(var(--color-primary-dark-rgb), 0.95)',
      className: 'notyf__toast--info',
      duration: 3000,
      icon: {
        className: 'material-icons md-36',
        tagName: 'i',
        color: 'var(--color-info)',
        text: 'info'
      }
    },
  ]
})

const growl = {
  notyf: notyf,
  types: {
    error: 'error',
    info: 'info',
    success: 'success',
    warn: 'warn'
  },

  open(type, message, title, options) {
    // const opts = _.merge(options, this.options.default, this.options[type])
    let msg = message
    if (title) msg = `<div class='notyf-title'>${title}</div><p>${message}</p>`

    return growl.notyf.open({ type: type, message: msg })
  },

  // Helper methods for various alerts types
  success(message, title, options = {}) { return growl.open(growl.types.success, message, title, options) },
  info(message, title, options = {}) { return growl.open(growl.types.info, message, title, options) },
  error(message, title, options = {}) { return growl.open(growl.types.error, message, title, options) },
  warn(message, title, options = {}) { return growl.open(growl.types.warn, message, title, options) }

}

export default growl
