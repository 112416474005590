// @ts-ignore //dont know why this is erroring, cycleback and ignore for now.
import Framework7 from 'framework7/lite-bundle';
import Framework7Svelte from 'framework7-svelte';
// adding this gets rid of the 'Cannot read properties of undefined (reading 'once')' error
import 'framework7/lite';
Framework7.use(Framework7Svelte);

// set to false in prod to disable logging
globalThis.logEnabled = true

import './i18n';

import injector from './injector'
injector.init()

import App from './App.svelte';
import './assets/index'

//old jqgrid formatters
import './shared/formatters'

// Init Svelte App
const app = new App({
  target: document.getElementById('app'),
});
export default app;
