import type { QueryParams } from '@yakit/core/api'
import dataApiFactory from '../dataApiFactory'
import RestDataService from '@yakit/core/api/RestDataService'
import { problemHandler } from '@yakit/ui/svelte'

/**
 * CustFamily Rest Api additions
 */
export default function (opts) {

    let api = RestDataService({ ...opts, key: 'ar/custFamily' })
    api.setParent = async (childId, parentId) => {
        let res
        try {
            res = await api.fetch({ method: 'POST', path: `${childId}/parent?parentId=${parentId}` }).json()
        } catch (e) {
            problemHandler.handleError(e)
        }
        return res
    }
    
    return api

}

