import { get, writable } from 'svelte/store'
import { isEmpty } from '@yakit/core/is'
import { makeQueryStore } from '@yakit/core/resource/QueryStore'
import { extend } from '@yakit/core/objectz'
import { PayDetailTranApi, PaymentDetailApi } from '~/api/autocash'

/**
 * Customer Rest Api additions
 */
function PayDetailQuery({ paymentQuery }) {
  const detailApi = PaymentDetailApi({ ky: paymentQuery.dataApi.ky })
  const payDetailQuery = makeQueryStore({ dataApi: detailApi })
  const currentPayment = paymentQuery.activeModel

  function checkPosted() {
    if (currentPayment.get().arPostedDate) {
      throw new Error('Posted payment cannot be changed')
    }
  }
  //new delete fn to check posted before call
  payDetailQuery.delete = async () => {
    if (currentPayment.get().arPostedDate) throw new Error('Posted payments cannot be changed')
    const paymentId = currentPayment.get().id
    const paymentDetailIds = [...get(payDetailQuery.selectedIds)]
    const result = await detailApi.deleteForPayment(paymentId, paymentDetailIds)
    await paymentQuery?.payDetailTranQuery?.reload()
    await reload()
    return result
  }
  //TODO: left here as reference to an old approach, remove if all works fine
  // const sumTotals = payDetailQuery.updateTotals
  // payDetailQuery.updateTotals = async () => {
  //   console.log('payment updateTotals, oob calc')
  //   //@ts-ignore
  //   const sumData = await sumTotals()
  //   let outOfBalance
  //   if (!Number.isNaN(sumData.payAmount)) { outOfBalance = currentPayment.get().amount - sumData.payAmount }
  //   console.log({outOfBalance})
  //   payDetailQuery.totals.set({...sumData, outOfBalance})
  //   return get(payDetailQuery.totals)
  // }

  payDetailQuery.totals.subscribe(sumData => {
    if(!currentPayment.get() || sumData.outOfBalance !== undefined) return
    let outOfBalance
    if (!Number.isNaN(sumData.payAmount)) { outOfBalance = currentPayment.get().amount - sumData.payAmount }
    payDetailQuery.totals.set({...sumData, outOfBalance})
    return get(payDetailQuery.totals)
  })

  const reload = async () => {
    await payDetailQuery.query()
  }
  /**
   * Makes the rpc calls to server
   */
  const rpc = async (name, data, params) => {
    const res = await detailApi.rpc(name, data, params)
    return res
  }

  extend(payDetailQuery, {
    // current total for the payment. Will get updated when paymentApi.currentPayment changes
    // or when triggered from a payment edit or delete
    /** gets the total values */
    getTotals() {
      return get(payDetailQuery.totals)
    },
    reload,
    /** Calls the duplicate/copy rpc on server */
    async duplicate(ids) {
      const results = await detailApi.post({ path: 'copy', json: { ids } })
      await payDetailQuery.reload()
      return results
    },
    bulkCreate(data) {
      return detailApi.bulkCreate(data)
    },
    rpc
  })

  return payDetailQuery
}

export default PayDetailQuery
