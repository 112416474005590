import safeStringify from 'fast-safe-stringify'
import { isObject } from './is'

/**
 * safe stringify, does not make it pretty
 * see https://github.com/davidmarkclements/fast-safe-stringify
 * stringify if its an object, otherwise return itself
 */
export default function stringify(obj, options){
  return isObject(obj) ? safeStringify(obj, null, 0, options) : obj
}

/**
 * by default pretty formats json with 2 space indent.
 */
export function prettyJson(obj, spacer = 2, options){
  return isObject(obj) ? safeStringify(obj, null, spacer, options) : obj
}
