import Logger from '@yakit/core/logger'; let log = Logger(false);
import {makeLink} from '../linker'
import appCtx from '~/injector'

const baseUrl = appCtx.get('envBaseUrl')

const imageLink = (rowData) => {
  let icon = rowData.imageFileName ? 'photo' : ''

  //for test we have 8080, will be same for prod? how to handle
  let href = rowData.imageFileName ? baseUrl + `/api/autocash/download?name=${rowData.imageFileName}` : '#'
  return `<a data-id="${rowData.id}" href="${href}" target="_blank" class="show-check-image external">
    <i class="material-icons-outlined md-18">${icon}</i>
  </a>`
}

const errorIcon = (rowData) => {
  let icon = rowData.isError ? 'error_outline' : ''
  return `<a payId="${rowData.id}"  href="#" class="color-danger">
    <i class="material-icons md-18">${icon}</i>
  </a>`
}

const formatters = {
  batchIdLink: (cellVal, options, rowdata) => {
    const ident = rowdata.id
    let href = makeLink('/autocash/batches', ident)
    return `<a class="showLink" data-id="${ident}" href="${href}" >${cellVal}</a>`
  },

  actionIcons: (cellVal, options, rowData) => {
    let _errorIcon = errorIcon(rowData)
    let _imageLink = imageLink(rowData)
    const actions = [_errorIcon, _imageLink]
    // const indicatorFields = {
    //   'errorDesc': {icon: 'error_outline', template: errorIcon},
    //   'imageFileName': {icon: 'photo', template: imageLink}
    // }
    // const actions = []
    // for (const [key, value] of Object.entries(rowData)) {
    //   if(indicatorFields[key]){
    //     const config = indicatorFields[key]
    //     actions.push(config.template(config.icon, cellVal, options, rowData))
    //   }
    // }
    log("options", options)
    log("rowData", rowData)
    options.colModel.title = ""

    return `<div style="white-space:nowrap;height:18px;">${actions.join('')}</div>`
  }
  ,
  isErrorFormatter: (cellVal, options, rowdata) => {
    if (cellVal) {
      return "<span style='color:red'>!</span>"
    } else {
      return ''
    }
  },
  errorMsgFormatter: (cellVal, options, rowData) => {
    if (rowData != null ? rowData.isError : false) {
      return `<a class="errorMsg"  uib-popover="${rowData.errorDesc}" payId="${rowData.id}" data-toggle="popover" href="#"
             data-container="#gbox_paymentGrid"><span style='color:red'>!</span></a><span style='display: none'>${rowData.errorDesc}</span>`
    } else {
      return ''
    }
  },
  attachments: (cellVal, options, rowData) => {
    if (cellVal) {
      return `<a class="attachments" uib-popover="${cellVal}" payId="${rowData.id}" data-toggle="popover" href="#"
             data-container="#gbox_paymentGrid"><i class="fa fa-paperclip"></i></a><span style='display: none'>#{cellVal}</span>`
    } else {
      return ''
    }
  },
  checkImage: (cellVal, options, rowData) => {
    if (cellVal) {
      return `<a class="checkImage" href="checkImage/show?id=${rowData.id}&type=payment" payId="#{rowData.id}" data-toggle="popover" href="#"
             data-container="#gbox_paymentGrid"><i class="fa fa-money"></i></a>`
    } else {
      return ''
    }
  },
  audit: (cellVal, options, rowData) => {
    if (cellVal != null) {
      return `<a class="audit"  uib-popover="${cellVal}" payId="${rowData.id}" data-toggle="popover" href="#"
             data-container="#gbox_paymentGrid"><i class="fa fa-comment-o"></i></a><span style='display: none'>${cellVal}</span>`
    } else {
      return ''
    }
  },
  paymentTranLink: (cellVal, options, rowdata) => {
    const ident = rowdata.arTranId
    let href = makeLink('/ar/trans', ident)
    return `<a class="showLink" data-id="${ident}" href="${href}" >${cellVal || ''}</a>`
  }
}

export default formatters
