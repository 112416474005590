import TranQuery from './resources/TranQuery'

export default {
  'tranQuery': ['factory', TranQuery],
}

//** example of setting up child */
// import injector from '../injector'
// import {BatchQuery} from './resources/BatchQuery'

// const appModule = {
//   'batchQuery': ['factory', BatchQuery],
// }
// const child = injector.createChild([ appModule ]);

// export default child
