<script>
  import { f7, Page, LoginScreen, ListInput, List, Button, BlockFooter, Row, Col, f7ready } from 'framework7-svelte'
  import auth from '../shared/auth'
  import growl from '@yakit/ui/growl'
  import stationImg from '../assets/img/station.svg'
  import { user } from '~/user'
  import appCtx from '~/injector'
  import { onMount } from 'svelte';
  const appState = appCtx.get('appState')

  let showLoginScreen = appState.showLoginScreen
  let username = ''
  let password = ''
  let errorMsg = ''
  let oktaSamlUrl = `${appState.apiUrl}/saml2/authenticate/okta`
  let f7router

  // onMount(() => {
  //   f7ready((f7) => {
  //     f7router = f7.views.main.router;
  //   })
  // })

  // $: $user.isCustomer && f7router.navigate({ name: 'ipay.home'})

  async function signIn() {
    try {
      await auth.login({ username, password } )
      f7.loginScreen.close()
      appState.isAuthenticated.set(true)
      growl.success('Login Successful', 'Welcome')

    } catch (e) {
      errorMsg = e
    }
  }
</script>

<LoginScreen
  class="demo-login-screen"
  opened={$showLoginScreen}
  onLoginScreenClosed={() => {
    $showLoginScreen = false
  }}
>
  <Page loginScreen>
    <div class="columns is-vcentered">
      <div class="column is-5">
        <div class="login-form">
          <div class="card-neu px-6">
            <div class="login-screen-title pt-4">Greenbill</div>
            <List form>
              <ListInput
                outline
                type="text"
                placeholder="Your username"
                name='login'
                value={username}
                onInput={(e) => (username = e.target.value)}
                required
                autocomplete="username"
              />
              <ListInput
                outline
                type="password"
                placeholder="Your password"
                name="pword"
                value={password}
                onInput={(e) => (password = e.target.value)}
                required
                autocomplete="current-password"
              />
            </List>
            <BlockFooter>
              <div class="buttons">
                <Button raised class="sign-in-button" onClick={signIn}>Sign In</Button>
              </div>
            </BlockFooter>
          </div>

          <div class='block-footer'>
            {#if errorMsg}
              <p class="help is-danger">
                {errorMsg}
              </p>
            {/if}

            <Button fill class="external" href={oktaSamlUrl}>Login with Okta</Button>
            <Button onClick={() => f7.loginScreen.close()}>Close</Button>
          </div>
        </div>
      </div>
      <div class="column is-hidden-mobile bg-body">
        <div class="pic">
          <img class="hero-image" src={stationImg} alt="">
        </div>
      </div>
    </div>
  </Page>
</LoginScreen>

<style>
  .bg-body {
    border-left: 1px solid #eee;
  }
  .login-form {
    margin: 0 15px;
  }
  .columns {
    align-items: center;
  }
  img {
    min-height: 100vh;
    position: relative;
    z-index: 2;
    display: block;
    margin: 0 auto 0 auto;
    max-width: 60%;
    width: 60%;
  }
  :global(.login-screen-content) {
    padding: 0;
  }
  :global(.login-screen-page) {
    /* background: #f5f5f5; */
    --f7-login-screen-content-bg-color: #f5f5f5;
    --login-color: #f5f5f5;
    --f7-list-bg-color: #f5f5f5;
  }
  :global(.login-screen-page .item-input-outline .item-input-wrap:after) {
    /* border: .0625rem solid #D1D9E6; */
    border: none;
  }
  :global(.list .item-input-outline input) {
    /* border: .0625rem solid #D1D9E6; */
    box-shadow: inset 2px 2px 5px #b8b9be, inset -3px -3px 7px #fff;
  }
  :global(.item-input-focused .item-floating-label) {
    /* border: .0625rem solid #D1D9E6; */
    /* box-shadow: inset 2px 2px 5px #b8b9be, inset -3px -3px 7px #fff; */
  }
  .login-screen-title {
    font-family: 'Poppins', sans-serif;
    font-size: 50px;
    color: #d2dddb;
    text-shadow: 2px 2px 3px #b8c7c2, -2px -2px 3px #ffffff;
    outline: none;
  }
  /* .footer {
    border-radius: 50px;
    background: #e0e0e0;
    box-shadow:  20px 20px 60px #bebebe,
                -20px -20px 60px #ffffff;
  } */

  .block-footer {
    margin-left: auto;
    margin-right: auto;
    max-width: 350px;
  }
  .card-neu {
    margin-left: auto;
    margin-right: auto;
    max-width: 350px;
    /* height: 300px; */
    border-radius: 30px;
    background: #f5f5f5;
    box-shadow: 7px 7px 14px #d5d5d5, -7px -7px 14px #ffffff;
  }

  :global(.sign-in-button) {
    width: 120px;
    margin-top: 10px;
    margin-bottom: 10px;
    transition: all 0.3s;
    border-radius: 15px;
    background: #f5f5f5;
    box-shadow: 5px 5px 10px #d5d5d5, -5px -5px 10px #ffffff;
  }

  .buttons {
    position: relative;
    display: flex;
    padding: 20px 0;
    overflow: hidden;
    box-sizing: border-box;
    justify-content: center;
  }
</style>
