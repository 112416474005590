import RestDataService from '@yakit/core/api/RestDataService'
import {_get} from '@yakit/core/objectz'
import { CustomerApi, TranApi, AdjustLines } from './ar'
import { BatchApi } from './autocash'
import injector from '../injector'
import { AttachmentApi } from './rally'
import SyncJobApi from './rally/SyncJobApi'
import CustFamilyApi from './ar/CustFamilyApi'
import JournalSummaryApi from './ar/JournalSummaryApi'

//we lazily create the service and keep it here, so when we call the getters we always get the same reference
const dataApiCache = {}

function mds({ key, factory = RestDataService, ...args }){
  const ky = injector.get('ky')
  if(dataApiCache[key]){
    return dataApiCache[key]
  } else {
    dataApiCache[key] = factory({ key, ky, ...args })
    return dataApiCache[key]
  }
}

/**
 * Factory for the rest api endpoints
 */
export const rallyApiFactory = () => {

  return {
    //Rally
    get appParam()    { return mds({ key:'rally/appParam', allowedOps: [ 'read', 'update', 'delete' ] }) },
    get activity()    { return mds({ key:'rally/activity' }) },
    get attachment()  { return mds({ key:'rally/attachment', factory: AttachmentApi }) },
    get company()     { return mds({ key:'rally/company' }) },
    get contact()     { return mds({ key:'rally/contact' }) },
    get org()         { return mds({ key:'rally/org' }) },
    get orgType()         { return mds({ key:'rally/orgType' }) },
    get orgTypeSetup(){ return mds({ key:'rally/orgTypeSetup', ops: [ 'update' ] }) },
    get tag()         { return mds({ key:'rally/tag', ops: [ 'read' ] }) },
    get syncJob()     { return mds({ key:'rally/syncJob', ops: [ 'read' ], factory: SyncJobApi  }) },

    // Seckeyty
    get user()         { return mds({ key:'rally/user' }) },
    get role()         { return mds({ key:'rally/role' }) },
    get roleUser()     { return mds({ key:'rally/roleUser', ops: ['create', 'delete'] }) },
  }
}

const arApiFactory = () => {

  return {
    //AR
    get agingSetup()  { return mds({ key:'ar/agingSetup' }) },
    get adjust()      { return mds({ key:'ar/adjust' }) },
    get adjustLine()  { return mds({ key:'ar/adjustLine'}) },
    get tran()        { return mds({ key:'ar/tran', factory: TranApi }) },
    get tranLine()    { return mds({ key:'ar/tranLine' }) },

    get customer()       { return mds({ key:'ar/customer', factory: CustomerApi }) },
    get custAccount()    { return mds({ key:'ar/custAccount' }) },
    get custClass()      { return mds({ key:'ar/custClass' }) },
    get custFamily()     { return mds({ key:'ar/custFamily', factory: CustFamilyApi  }) },
    get custAccountType(){ return mds({ key:'ar/custAccountType' }) },

    get tranState()   { return mds({ key:'ar/tranState' }) },
    get tranType() { return mds({ key: 'ar/tranType' }) },
    get baseTranType() { return mds({ key: 'ar/baseTranType' }) },

    get glBatch() { return mds({ key: 'ar/glBatch' }) },
    get glPostPeriod() { return mds({ key: 'ar/glPostPeriod' }) },

    //AR setups
    get lockbox()     { return mds({ key:'ar/lockbox' }) },
    get batchType()   { return mds({ key:'ar/batchType' }) },
    get status()      { return mds({ key:'ar/status' }) },
    get reason()      { return mds({ key:'ar/reason' }) },
    get reasonGroup() { return mds({ key:'ar/reasonGroup' }) },

    get term()        { return mds({ key:'ar/term' }) },
    get refnumGen()   { return mds({ key:'ar/refnumGen' }) },

    get custDash()    { return mds({ key:'ar/custDash' }) },
  }
}

const autocashApiFactory = () => {

  return {
    get batch() { return mds({ key:'autocash/batch', factory: BatchApi })},

    //autocash admin/setup
    get corCode() { return mds({ key:'autocash/corCode' }) },
    get micrBlacklist() { return mds({ key:'autocash/micrBlacklist' }) },
    get reasonTolerance() { return mds({ key:'autocash/reasonTolerance' }) },
    get layoutRecord() { return mds({ key:'autocash/layoutRecord' }) },
    get layoutImport() { return mds({ key:'autocash/layoutImport' }) },
    get layoutField() { return mds({ key:'autocash/layoutField' }) },

  }
}

const ipayApiFactory = () => {

  return {
    get payTran() { return mds({ key:'ipay/payTran' })},
    get tranLine() { return mds({ key:'ipay/tranLine' })},
    get wallet() { return mds({ key:'ipay/wallet' })},
    get paySchedule() { return mds({ key:'ipay/paySchedule' })},
  }
}

const glApiFactory = () => {

  return {
    get glAccount()  { return mds({ key:'ar/glAccount' }) },
    get glBatch()  { return mds({ key:'ar/glBatch' }) },
    get glJournal() { return mds({ key: 'ar/glJournal' }) },
    get glJournalSummary() { return mds({ key: 'ar/glJournal/summary', configPath: 'ar/glJournal' }) },
    get glJournalsSummary() { return mds({ key: 'ar/glJournalsSummary', factory: JournalSummaryApi }) },
    get tranJournal() { return mds({ key: 'ar/tranJournal' }) },
    get adjustJournal() { return mds({ key: 'ar/adjustJournal' }) },
  }
}

/**
 * Factory for the rest api endpoints
 */
export const DataApiFactory = () => {

  const o = {
    get(key){
      return _get(this,key)
    },
    rally: rallyApiFactory(),
    ar: arApiFactory(),
    autocash: autocashApiFactory(),
    ipay: ipayApiFactory(),
    gl: glApiFactory()
  }
  // console.log("DataApiFactory created")
  return o
}

const dataApiFactory = DataApiFactory()

export default dataApiFactory


