import { writable } from 'svelte/store';
import $ from './dom';
import DataManager from './DataManager';
import CellManager from './CellManager';
import ColumnManager from './ColumnManager';
import RowManager from './RowManager';
import Style from './Style';
import Keyboard from './Keyboard';
import TranslationManager from './TranslationManager';
import getDefaultOptions from './defaults';

let defaultComponents = {
  DataManager,
  CellManager,
  ColumnManager,
  RowManager,
  Style,
  Keyboard
};

/**
 * general overall manager for the flex table.
 * This is also set as the context under ctxKey for chid components
 */
class TableManager {

  editableCells = false //Off by default for now until we work through it

  toastMessage = writable("")
  showFreeze = writable(false)
  //buildOptions sets this
  options
  //reference to the resource that backs this
  resource

  constructor(wrapper, options, resource) {
    TableManager.instances++;
    this.wrapper = wrapper;
    this.resource = resource
    // console.log("this.options.data is true", this.options.data)
    this.initializeTranslations(options);
    this.setDefaultOptions();
    this.buildOptions(options);
    this.prepare();
    this.initializeComponents();
    this.datamanager.init()
  }

  initializeTranslations(options) {
    this.language = options.language || 'en';
    this.translationManager = new TranslationManager(this.language);

    if (options.translations) {
      this.translationManager.addTranslations(options.translations);
    }
  }

  setDefaultOptions() {
    this.DEFAULT_OPTIONS = getDefaultOptions(this);
  }

  buildOptions(options) {
    this.options = this.options || {};

    this.options = Object.assign(
      {}, this.DEFAULT_OPTIONS,
      this.options || {}, options
    );

    options.headerDropdown = options.headerDropdown || [];
    this.options.headerDropdown = [
      ...this.DEFAULT_OPTIONS.headerDropdown,
      ...options.headerDropdown
    ];

    // custom user events
    this.events = Object.assign(
      {}, this.DEFAULT_OPTIONS.events,
      this.options.events || {},
      options.events || {}
    );
    this.fireEvent = this.fireEvent.bind(this);
  }

  prepare() {
    this.prepareDom();
    this.unfreeze();
  }

  initializeComponents() {
    let components = Object.assign({}, defaultComponents, this.options.overrideComponents);
    let {
      Style,
      Keyboard,
      DataManager,
      RowManager,
      ColumnManager,
      CellManager
    } = components;

    this.style = new Style(this);
    this.keyboard = new Keyboard(this.wrapper);
    this.datamanager = new DataManager(this.options, this);
    this.rowmanager = new RowManager(this);
    this.columnmanager = new ColumnManager(this);
    this.cellmanager = new CellManager(this);
  }

  prepareDom() {
    this.datatableWrapper = $('.datatable', this.wrapper);
    this.header = $('.dt-header', this.wrapper);
    this.footer = $('.dt-footer', this.wrapper);
    this.bodyScrollable = $('.dt-scrollable', this.wrapper);
    this.pasteTarget = $('.dt-paste-target', this.wrapper);
    this.dropdownContainer = $('.dt-dropdown-container', this.wrapper);
  }

  refresh(data) {
    this.datamanager.init(data);
    this.render();
    this.setDimensions();
  }

  destroy() {
    this.wrapper.innerHTML = '';
    this.style.destroy();
    this.fireEvent('onDestroy');
  }

  appendRows(rows) {
    this.datamanager.appendRows(rows);
    this.rowmanager.refreshRows();
  }

  refreshRow(row, rowIndex) {
    this.rowmanager.refreshRow(row, rowIndex);
  }

  render() {
    // this.renderHeader();
    this.renderBody();
  }

  renderBody() {
    this.datamanager.rowStore.set(this.datamanager.getRowsForView())
  }

  // setDimensions() {
  //   // this.style.setDimensions();
  // }

  getColumn(colIndex) {
    return this.datamanager.getColumn(colIndex);
  }

  getColumns() {
    return this.datamanager.getColumns();
  }

  getRows() {
    return this.datamanager.getRows();
  }

  getCell(colIndex, rowIndex) {
    return this.datamanager.getCell(colIndex, rowIndex);
  }

  getColumnHeaderElement(colIndex) {
    return this.columnmanager.getColumnHeaderElement(colIndex);
  }

  getViewportHeight() {
    if (!this.viewportHeight) {
      this.viewportHeight = $.style(this.bodyScrollable, 'height');
    }

    return this.viewportHeight;
  }

  sortColumn(colIndex, sortOrder) {
    this.columnmanager.sortColumn(colIndex, sortOrder);
  }

  removeColumn(colIndex) {
    this.columnmanager.removeColumn(colIndex);
  }

  scrollToLastColumn() {
    this.datatableWrapper.scrollLeft = 9999;
  }

  //show loading overlay
  freeze() {
    this.showFreeze.set(true)
  }

  unfreeze() {
    this.showFreeze.set(false)
  }

  updateOptions(options) {
    this.buildOptions(options);
  }

  fireEvent(eventName, ...args) {
    // fire internalEventHandlers if any
    // and then user events
    const handlers = [
      ...(this._internalEventHandlers[eventName] || []),
      this.events[eventName]
    ].filter(Boolean);

    for (let handler of handlers) {
      handler.apply(this, args);
    }
  }

  on(event, handler) {
    this._internalEventHandlers = this._internalEventHandlers || {};
    this._internalEventHandlers[event] = this._internalEventHandlers[event] || [];
    this._internalEventHandlers[event].push(handler);
  }

  log() {
    if (this.options.logs) {
      // eslint-disable-next-line no-console
      console.log.apply(console, arguments);
    }
  }

  translate(str, args) {
    return this.translationManager.translate(str, args);
  }

  showToastMessage(message, hideAfter) {
    this.toastMessage.set(message)

    if (hideAfter) {
      setTimeout(() => {
        this.clearToastMessage();
      }, hideAfter * 1000);
    }
  }

  clearToastMessage() {
    this.toastMessage.set('')
  }

}

TableManager.instances = 0;

export default TableManager;
