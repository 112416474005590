<script>
  import { Page, Navbar, Block } from 'framework7-svelte';
</script>

<Page>
  <Navbar title="Not found" backLink="Back" />

  <Block strong>
    <p>Sorry</p>
    <p>Requested content not found.</p>
  </Block>
</Page>
