const transBase = '/ar/trans'
const custAccountBase = '/ar/custaccounts'
const custBase = '/ar/customers'

export function getLink(resource, id){
  switch (resource) {
    case 'customer':
      return `${custBase}/${id}`
    case 'custAccount':
      return `${custAccountBase}/${id}`
    case 'arTran':
      return `${transBase}/${id}`
    case 'tran':
      return `${transBase}/${id}`
    default:
      return ''
  }
}

export function makeLink(base, end){
  return `${base}/${end}`
}
