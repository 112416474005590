/**
 * deeply gets all the values flattened nested or not
 * Used in model to check for errors.
 * @returns array of values
 */
export default function deepValues(object) {
  let results = [];

  for (const [, value] of Object.entries(object)) {
    const values = typeof value === 'object' ? deepValues(value) : [value];
    results = [...results, ...values];
  }

  return results;
}
