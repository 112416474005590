/**
 * super simple logger.
 * import Logger from '@yakit/core/logger/debugger'
 * let log = Logger(false) //true or false to enable/disable
 *
 * then just call
 * log("foo", someVar)
 */
const Logger = (enabled = true) => {
  //globalThis.logEnabled wins, if its off then make it false
  //NOT WORKING
  // console.log("globalThis.logEnabled", globalThis.logEnabled)
  // enabled = globalThis.logEnabled === false ? false : enabled
  let globalEnabled = window.logEnabled == undefined ? true : window.logEnabled
  enabled = globalEnabled ? enabled : false
  
  // eslint-disable-next-line no-console
  return enabled ? console.log.bind(window.console) : ()=>{}
}

// export function logz(msg){
//   let enabled = window.logEnabled == undefined ? true : window.logEnabled
//   Logger(enabled)(msg)
// }

export default Logger


