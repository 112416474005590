import type { KyInstance } from '@yakit/core/api';
import {get, writable, type Writable} from 'svelte/store';

export const isAuthenticated = writable(false)

export interface AppState {
  // [x: string]: any;
  auth: any;
  showLoginScreen: Writable<boolean>;
  getAuthenticated(): boolean;
  isAuthenticated: Writable<boolean>;
  apiUrl: string;
  dataApiFactory: any;
  info: any;
  // currently logged in user info
  user: Writable<any>;
  // sidebar config
  sidebarConfig: Writable<any>;
  // login(o:{username: string, password: string}): Promise<void>;
  logout(): void;
  requireLogin(): void
}
/**
 * Factory for the rest api endpoints
 */
export function AppStateFactory(): AppState{
  const o: AppState = {

    auth: undefined, //inject this

    showLoginScreen: writable(false),
    //will be true if isAuthenticated
    getAuthenticated() {
      return get(isAuthenticated)
    },
    isAuthenticated: isAuthenticated,
    apiUrl: undefined,
    dataApiFactory:  undefined,
    info: {},
    // currently logged in user info
    user: writable({}),
    // sidebar config
    sidebarConfig: writable({}),
    //short cut to logout and requireLogin
    logout(){
      o.auth.logout()
      o.requireLogin()
    },
    requireLogin(){
      isAuthenticated.set(false)
      o.showLoginScreen.set(true)
    }
  }
  return o
}

const appState = AppStateFactory()

export default appState
