import RestDataService from '@yakit/core/api/RestDataService'

/**
 * Customer Rest Api additions
 */
 export default function(opts){

  let tranApi = RestDataService({ ...opts, key: 'ar/tran' })
  const _setupQueryParams = tranApi.setupQueryParams

  /**
   * amount sums for tran
   * @param {Object} queryParams 
   */
   tranApi.sumTotals = async (queryParams) => {
    const fields = ['origAmount', 'amount']
    const projections = {}
    fields.forEach(field => projections[field] = 'sum')
    const params = {
      projections,
      ...queryParams
    }

    let searchParams = tranApi.setupQueryParams(params)
    const res = await tranApi.fetch({ searchParams }).json()
    const dta = res.data[0]
    const values = {}
    fields.forEach(field => values[field] = isNaN(dta[`${field}`]) ? '' : parseFloat(dta[`${field}`]))

    return values
  }

  /*
   * Hack for trans search form we have customer and customer.related.collector filters
   * which breaks select because of adding to form model nested object, to avoid it setting key with _ instead of dot
   * for example, this will convert the customer_related_collector to customer.related.collector
   * TODO look into fixing the storeTools or search form so this is not needed. Will need to think through the concept and test. 
   */
  const transformFieldKeys = (filters) => {
    const keys = Object?.keys(filters||{})
    const result = {}
    keys.forEach(key => {
        result[key.replaceAll('_', '.')] = filters[key]
    })

    return result
  }
  
  tranApi.setupQueryParams = (params) => {
    params.q = transformFieldKeys(params.q)
    return _setupQueryParams(params)
  }

  return tranApi
}

