/**
 * Utils used in modelEditor but may be helful for others.
 */

import { isNil } from '../utils/is';

/**
 * deeply sets all values to value.
 * Useful for taking an object and using it as a schema to track whats been touched/ changed/ or has errors
 * example: deepUpdate({a: 'foo', b: {c: 5} }, false) == {a: false, b:{c:false}}
 */
export default function deepUpdate(object, value) {
  if (Array.isArray(object)) {
    return typeof object[0] === 'object' ? object.map((o) => deepUpdate(o, value)) : value;
  }
  const copy = {};
  for (const key in object) {
    copy[key] =
      typeof object[key] === 'object' && !isNil(object[key]) ? deepUpdate(object[key], value) : value;
  }
  return copy;
}
