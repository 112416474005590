export const MANGO_FILTER_KEY = 'mangoFilter'

export const mangoFilterTransform = (values) => {
    if(!values) return values
    const mangoFilters = values[MANGO_FILTER_KEY] //|| undefined
    if (mangoFilters) {
      delete values[MANGO_FILTER_KEY]

      //regexp adds quates around each word
      //.replace(/(?![0-9]+)([a-zA-z0-9.*#]+)/g, '"$1"')
      values = {...values, ...JSON.parse(`{${mangoFilters}}`)}
    }

    return values
  }