/**
 * Wraps dayjs with the useful plugins we need.
 */
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(localeData)

export default dayjs

export const dateFormats = {
  date: 'YYYY-MM-DD',
  'datetime-local': 'YYYY-MM-DDTHH:mm:ss'
}

export const isoDate = {
  now(){
    return dayjs.utc().format('YYYY-MM-DD')
  }
}

export const isoDateTime = {
  now(){
    return dayjs.utc().format()
  }
}
