import { isPlainObject, isString, isMatchWith } from './is'
import {toString} from '../dash'
import {get} from 'svelte/store';
import { _get } from '../objectz';

/**
 * gets the item in the data list for the id.
 * If the passed in data is a store then it will do a get(list) to unwrap its data first
 *
 * @param {*} data the array or store of objects to find on
 * @param {*} id the id value to find
 * @param {string} idProp the property that has the id, defaults to 'id'
 * @returns the item or false if not found
 */
export function findById(data, id, idProp = 'id'){
  //if it has subscribe then assume its a store and use get function to get data
  const list = (typeof data.subscribe === "function") ? get(data): data
  const idx = findIndexById({ list, id , idProp })
  return idx === -1 ? false : list[idx]
}

/**
 * searches array for id key match
 *
 * @param {{list: array, id: object, idProp?: string}} param0 should have data and id, can also pass in idField if identity is other than 'id'
 * @returns {number} the index or -1 if not found
 */
export const findIndexById = ({ list, id, idProp = 'id'}) => {
  const idx = list.findIndex((item) => _get(item, idProp) === id)
  // if (idx === -1) throw Error(`${id} not found`)
  return idx
}

/**
 * deep checks if property includes the string or if not string === the searchkey
 *
 * @param {*} obj the object to look into
 * @param {*} searchKey the searchKey
 * @returns true or false
 */
export const hasSomeDeep = (obj, searchKey)  => {
  return Object.keys(obj).some(key => {
    const val = obj[key]
    if (isPlainObject(val)) {
      return hasSomeDeep(val, searchKey)
    } else {
      if (isString(val)) {
        return val.toLowerCase().includes(toString(searchKey).toLowerCase())
      } else {
        return val == searchKey
      }
    }
  })
}

/**
 * hasSomeDeep to filter array
 *
 * @param {*} arr the array to filter
 * @param {*} searchKey the searchKey
 * @returns the filtered array of matches
 */
export const findSomeDeep = (arr, searchKey)  => {
  return arr.filter(obj => hasSomeDeep(obj, searchKey))
}

/**
 * query by example, uses lodash isMatch
 * @param {*} data
 * @param {*} qbe
 */
export function qbe(data, qbe) {
  let filteredItems = data.filter(it => {
    return isMatchWith(it, qbe, (objValue, searchVal) => {
      //overrides so string does an includes instead of equality check
      if (isString(objValue)) {
        return objValue.toLowerCase().includes(toString(searchVal).toLowerCase())
      }
      return undefined // return undefined puts it through the built in lodash match
    })
  })
  return filteredItems
}
