import NotFound from '../404.svelte';

// Default route (404 page). seems it MUST BE THE LAST item, at least as far as we know so far.
let route404 = {
    path: '(.*)',
    component: NotFound,
    isMenuItem: false
}

export default route404
