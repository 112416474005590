import Logger from '@yakit/core/logger'; const log = Logger(false)
import About from '../about.svelte';
import SidebarMenu from '../sidebar/SidebarMenu.svelte';
import growl from '@yakit/ui/growl'
import injector from '~/injector'
//-- end imports --

// Pages
const commonRoutes = () => {
  return [
    // Index page
    // About page
    {
      name: 'About',
      path: '/about(/?)',
      link: '/about', //if path is a matching pattern then when doing menu will look here first
      component: About,
      isMenuItem: false
    },
    {
      path: '/sidebar-menu',
      component: SidebarMenu,
      isMenuItem: false
    }
  ];
}

export default commonRoutes;
