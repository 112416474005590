<script>
  import { colorClasses, classNames, restProps } from './shared';
  import {camelCase} from '@yakit/core/dash'
  import {getService} from '@yakit/core/injector'
  import MdiIcon from 'mdi-svelte/src/Index.svelte';
  // import {
  //     mdiCardsSpade,
  //     mdiMonitorDashboard,
  //     mdiThumbUp,
  //     mdiThumbUpOutline
  //   } from '@mdi/js'

  // this causes events to get scrambled
  // import { useTooltip } from 'framework7-svelte/shared/use-tooltip.js';

  export let style = undefined

  let className = undefined
  export { className as class }

  export let icon = undefined
  export let size = undefined
  export let rotate = undefined
  // material icon has 4 kinds, outline, fill, rounded, sharp and two-tone, default is outline. NA to f7 icons
  export let kind = 'outlined'

  export let vendor = 'material'

  let mdiIcon
  let el
  let classes = {
    icon: true,
  };

  $: iconText = icon ? iconTextComputed(icon) : ''

  function iconTextComputed(_) {
    let textComputed = icon
    if (icon.indexOf('material:') >= 0 || icon.indexOf('f7:') >= 0 || icon.indexOf('mdi:') >= 0) {
      const parts = icon.split(':')
      vendor = parts[0]
      textComputed = parts[1]
    }
    return textComputed;
  }


  $: if (icon) {
    if (vendor === 'material') {
      let kindClass = kind.startsWith('fill') ? '' : `-${kind}`
      classes[`material-icons${kindClass}`] = true
    } else if(vendor === 'f7'){
      classes['f7-icons'] = true
    } else if(vendor === 'mdi'){
      classes['mdi'] = true
      classes[`mdi-${iconText}`] = true
      let camelName = camelCase(iconText)
      camelName = camelName[0].toUpperCase() + camelName.substring(1)
      camelName = `mdi${camelName}`
      let iconLookup = getService('iconLookup')
      mdiIcon = iconLookup[camelName]
      iconText = ''
    }
  }

  $: iconClasses = classNames(className, classes, colorClasses($$props));

  $: iconSize = typeof size === 'number' || parseFloat(size) === size * 1 ? `${size}px` : size;

  $: iconStyle =
    (style || '') +
    (iconSize
      ? `;font-size: ${iconSize}; width: ${iconSize}; height: ${iconSize}`.replace(';;', '')
      : '');
</script>



{#if vendor === 'mdi'}
  <i
    class={iconClasses}
    bind:this={el}
    {...restProps($$restProps)}
  >
     <MdiIcon path={mdiIcon} size={iconSize} {rotate}/>
  </i>

{:else}
  <i
    style={iconStyle}
    class={iconClasses}
    bind:this={el}
    {...restProps($$restProps)}
  >
    {iconText || ''}
    <slot />
  </i>
{/if}

<style lang="scss">
  .icon.mdi {
    display: flex;
    align-items: center;
  }
</style>
