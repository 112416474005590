import { makeKy, makeAppConfigApi } from "@yakit/core/api";
import { makeAuthService } from './shared/auth'
import { makeAppState } from './RcmAppState'
import * as mdiIcons from './assets/mdi-icons'
import {user} from './user'
import dataApiFactory from './api/dataApiFactory'
import { createTranShowController } from './ar/tran/tranShowController'

// eslint-disable-next-line no-undef
const baseUrl = configData.base_url
// eslint-disable-next-line no-undef
export let isDev = (configData.dev == true)

const apiConfig = {
  baseUrl,
  apiUrl: `${baseUrl}/api`,
  enableAuthHeader: true
}

const getIsAuthenticated = ({ injector }) => injector.get('appState').isAuthenticated

/**
 * the module setup for wiring the injector appCtx.
 * reminder, keys need to be strings so they are not minified
 */
export default {
  '__init__': [ 'ky', 'authService', 'appState', 'dataApiFactory'],
  // if an object asks for 'ky', the injector will call makeKy(...) to produce it, same down the line
  ky: ['factory', makeKy],
  authService: ['factory', makeAuthService],
  appState: ['factory', makeAppState],
  'user': ['value', user],
  appConfigApi: ['factory', makeAppConfigApi],
  //short cut to store for isAuthenticated
  isAuthenticated: ['factory', getIsAuthenticated ],
  tranShowController: ['factory', createTranShowController ],
  iconLookup: ['value', mdiIcons ],
  apiConfig: ['value', apiConfig],
  apiUrl: ['value', apiConfig.apiUrl],
  isDev: ['value', isDev],
  envBaseUrl: ['value', isDev ? apiConfig.baseUrl : ''],
  'dataApiFactory': ['value', dataApiFactory]
}


