import {getLink} from '../linker'

const formatters = {
  // Show Customer link
  tranCustomerLink: (cellVal, options, rowData) => {
    const ident = rowData.customer?.id
    let href = getLink('customer', ident)
    return `<a class="showLink" data-id="${ident}" href="${href}" >${cellVal}</a>`
  },

  // Show CustAccount link
  tranCustAccountLink: (cellVal, options, rowData) => {
    const ident = rowData.custAccount?.id
    if(!ident) return ''
    let href = getLink('custAccount', ident)
    return `<a class="showLink" data-id="${ident}" href="${href}" >${cellVal}</a>`
  },

  tranGridLink: function(cellVal, options, rowData) {
    const ident = rowData.id
    let href = getLink('arTran', ident)
    return `<a class="showLink" data-id="${ident}" href="${href}" >${cellVal}</a>`
  },
  adjustTranGridLink: function(cellVal, options, rowData) {
    const ident = rowData.arTran.id
    let href = getLink('arTran', ident)
    return `<a class="showLink" data-id="${ident}" href="${href}" >${cellVal}</a>`
  }

}

export default formatters
