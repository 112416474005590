/* eslint-disable space-before-function-paren*/
import RestDataService from '@yakit/core/api/RestDataService'

/**
 * Customer Rest Api additions
 */
export default function (opts) {
  const attachmentAPi = RestDataService({ ...opts, key: 'rally/attachment' })

  // attachmentAPi.upload = async (data) => {
  //   return {}
  // }
  attachmentAPi.download = async ({ id, name }) => {
    const result = await attachmentAPi.fetch({ method: 'get', path: `/download/${id}/${name}` }).json()
    //@ts-ignore
    return result.data
  }

  return attachmentAPi
}
