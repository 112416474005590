/**
 * Wrapper helper around https://github.com/sindresorhus/ky.
 */
import type { KyInstance } from 'ky/distribution/types/ky'
import type { Options, ResponsePromise } from 'ky';
import { ensurePrefix } from '../utils/ensure'

export {KyInstance, Options, ResponsePromise}

export type ID = string | number;

export interface FetchOptions extends Options {
  path?: string | number;
}

export interface Fetcher {
  pathKey: string;
  ky: KyInstance;

  /**
   * pass method into args. can also pass 'path' and it will be appended to key.
   * path is where to put the {id} path param. so to get /api/customer/1, pass 1 into the path
   * NOTE: returns the reponsePromise which has a json method that can be called instead of waiting on this promise.
   *
   * @param opts - the method is the rest verb, path is the postfix to append
   * @returns the `ResponsePromise`, most often call the .json() method
   */
  fetch(opts: FetchOptions): ResponsePromise;

  /**
   * get by the id.
   * @param id - adds to end of the path
   * @param opts - any extra options to pass through
   * @returns calls .json() on the Response and returns its promise
   */
  getById(id: ID, opts?: Options): Promise<object>;

  /**
   * call fetch with `method:post` and returns the .json() `Promise`
   * @example
   * ```
   * //short cut for ccalling
   * fetch({ method: 'post', ...opts}).json()
   * ```
   */
  post(opts:FetchOptions): Promise<object>;

  /** calls fetch with `method:put`  */
  put(opts:FetchOptions): Promise<object>;
  /** calls fetch with `method:patch`  */
  patch(opts:FetchOptions): Promise<object>;
  /** calls fetch with `method:delete`  */
  delete(opts:FetchOptions): Promise<object>;
  //mocks a delay
  delay(ms: number): Promise<void>;
}

/**
 * object uses ky to make fetch rest calls tied to a specific base endpoint.
 * For example: If ky baseUrl is http://foo.9ci.io/api and path passed in is go/bar then
 * all the verb calls will be against http://foo.9ci.io/api/go/bar.
 * The 'path' is an action param, a term for the controller method or id on the server. AKA "rpc" or ID.
 *  - the path is just appended to the base endpoint url.
 *  - also where we put the id. so /api/go/bar/{id} is /api/go/bar/{path}
 *
 * @param {object} ky and paythey
 * @returns the kyFetch object helper
 */
export function makeFetcher({ ky, pathKey }: { ky: KyInstance, pathKey: string }): Fetcher {

  let o: Fetcher = {
    //inject
    pathKey,
    ky,

    fetch({method = 'get', path = '', ...opts}){
      path = ensurePrefix(path, '/')
      let fullPath = `${pathKey}${path}`
      // const ky = o.getKy()
      return ky(fullPath, {...opts, method})
    },

    async getById(id, opts){
      //get is default, we dont need to pass it in
      return o.fetch({ path: id , ...opts}).json()
    },

    async post(opts){
      return o.fetch({...opts, method: 'post'}).json()
    },

    async put(opts){
      return o.fetch({...opts, method: 'put'}).json()
    },

    async patch(opts){
      return o.fetch({...opts, method: 'patch'}).json()
    },

    async delete(opts){
      return o.fetch({...opts, method: 'delete'}).json()
    },
    async delay(ms = 0){
      return new Promise(resolve => setTimeout(resolve, ms))
    }
  }
  return o
}
