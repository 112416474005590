import dataApiFactory from '../dataApiFactory'
import RestDataService from '@yakit/core/api/RestDataService'
import { extend } from '@yakit/core'

/**
 * Customer Rest Api additions
 */
 export default function(opts){

  let familyApi = dataApiFactory.ar.custFamily

  let api = RestDataService(opts)

  return extend(api,{
    listFamily: async (id, listType) => {
      const familyList = await familyApi.search({relativesOfId: id, listType: listType})
      return familyList.data.map(family => family.child)
    },
    getParent: async (childId) => {
      const familyList = await familyApi.search({childId})
      return familyList.data[0]?.parent
    },
    getAncestors: async (childId) => {
      const parent = (await api.getParent(childId))
      let grandParent
      if (parent) {
        grandParent = await api.getParent(parent.id)
      }
      return {parent, grandParent}
    },
    setPrimaryContact: async (customerId, contactId) => {
      const result = await api.update({id: customerId, org: {contact: {id: contactId}} })
      return result
    },
  })

}

