
const formatters = {

  attachments: (cellVal, options, rowData) => {

    if (cellVal) {
      return `\
        <a class="attachments" popUpParams="${cellVal}" data-toggle="popover" href="#"
        data-container="#gbox_arTranGrid"><i class="fas fa-paperclip"></i></a><span style='display: none'>${cellVal}</span>\
      `
    } else {
      return ''
    }
  },

  iconLink: (cellVal, options, rowData) => {
    if (cellVal) {
      const {
        icon
      } = options.colModel
      return /* ArTranLinkServ( */`<i class='fa ${icon}'></i>`//, options, rowData, {activeTab: 'activity'})
    } else {
      return ''
    }
  },

}

export default formatters
