
// Takes a nested Javascript object and flatten it.
// see: https://github.com/hughsk/flat
export default function flattenObject(target, opts) {
  if (opts == null) { opts = { delimiter: '.' } }
  const transformKey = opts.transformKey || keyIdentity
  const maxDepth = opts.maxDepth

  const { delimiter } = opts

  // const getKey = function(key, prev) {
  //   if (prev) { return prev + delimiter + key } else { return key }
  // }


  function step(object, prev, currentDepth) {
    currentDepth = currentDepth || 1
    Object.keys(object).forEach(function(key) {
      const value = object[key]
      const isarray = opts.safe && Array.isArray(value)
      const type = Object.prototype.toString.call(value)
      const isbuffer = isBuffer(value)
      const isobject = (
        type === '[object Object]' ||
        type === '[object Array]'
      )

      const newKey = prev
        ? prev + delimiter + transformKey(key)
        : transformKey(key)

      if (!isarray && !isbuffer && isobject && Object.keys(value).length &&
        (!opts.maxDepth || currentDepth < maxDepth)) {
        return step(value, newKey, currentDepth + 1)
      }

      output[newKey] = value
    })
  }

  var output = {}
  step(target)
  return output
}


function isBuffer(obj) {
  return obj &&
    obj.constructor &&
    (typeof obj.constructor.isBuffer === 'function') &&
    obj.constructor.isBuffer(obj)
}

function keyIdentity(key) {
  return key
}
