// holder for the routes and main view init settings
import Logger from '@yakit/core/logger'; let log = Logger(false);
import { writable, get } from 'svelte/store'
// import main from './main';
// import ipay from './ipay';
import common from './common';
import route404 from './404';

let _routes = []

/** sets the routes, called from  */
function setRoutes(newRoutes) {
  _routes = newRoutes
  isReady.set(true)
}

const isReady = writable(false)
const isViewReady = writable(false)
const routeByKey = (key: string): Record<string, any> => _routes.find(v => v.name === key)
const getPath = (key: string): string => routeByKey(key)?.path

// const common = async () => (await import('./common')).default()
//dynamic modules
const main = async () =>  (await import('./main')).default()
const ipay = async () =>  (await import('./ipay')).default()

const routesHolder = {
  /** store will be true when the routes are set */
  isReady,
  /** store will be true when main view is ready */
  isViewReady,
  /** gets the route for the key */
  routeByKey,
  /** gets the path for key*/
  getPath,

  // setupIpayRoutes: () => setRoutes([...common(), route404]),
  // FIXME getting ReferenceError: Cannot access 'injector' before initialization, dont get it.
  // setupIpayRoutes: () => setRoutes([...common(), ...ipay(), route404]),
  setupIpayRoutes: async () => {
    const ipayRoutes = await ipay()
    setRoutes([...common(), ...ipayRoutes, route404])
  },

  setupRcmRoutes: async () => {
    log("********** setupRcmRoutes")
    const mainRoutes = await main()
    setRoutes([...common(), ...mainRoutes, route404])
  },

  /** the routes for either ipay or rcm depending on what was setup */
  getRoutes: ():any[] => _routes,

  /** sets the routes, called from  */
  setRoutes: (newRoutes) => {
    _routes = newRoutes
    isReady.set(true)
  },
  /** gets the base common routes, the basics to log in */
  getCommonRoutes: () => {
    // const com = await common()
    return [...common(), route404]
  },
}

export default routesHolder

