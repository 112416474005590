/**
 * Wrapper around the currency lib to provide prefered defaults for money.
 * By default the symbol is blanked out
 */
import Currency, { type Options} from "currency.js";

export const currencyDefaults: Options = {
  // symbol: '$',
  symbol: '',
  //other options
  // separator: ',',
  // decimal: '.',
  // errorOnInvalid: false,
  // precision: 2,
  // pattern: '!#',
  // negativePattern: '-!#',
  // fromCents: false
}

export interface Money extends Currency {
  /** formats with the $ or whatever the symbol is prefixed */
  format$(): string
  abs(): Money
}

export default function currency(value?: Currency.Any, opts?: Options): Money {
  const cur = Currency(value, Object.assign({}, currencyDefaults, opts))
  Object.assign(cur, {
    format$: () => cur.format({symbol: '$'}),
    abs: () => currency(Math.abs(cur.value))
  })
  return cur as Money
}


