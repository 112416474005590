// @ts-nocheck
import {isEqual, isPlainObject} from '../utils/is'
import Logger from '../logger'; let log = Logger(false);
/**
 * returns diff object with changes. arrays are not checked. if they are different it returns whole thing as changed.
 * Opinionateed in that if id field exists it always keeps it.
 *
 * @param {*} target the orginal or source object
 * @param {*} changed the changed object
 * @param {*} requiredFields
 * @returns
 */
export default function deepDiff(target, changed, requiredFields) {
  log("diff", target, changed)
  if(!target) return changed
  let diffobj = Object.entries(changed).reduce(function(result, [key, value]) {
    if (!isEqual(value, target[key])) {
      if (isPlainObject(value)) {

        result[key] = deepDiff(target[key], value);
      } else {
        result[key] = value;
      }
    }
    return result;
  }, {});
  //add id if it exists
  if(target.id && !diffobj.id) diffobj.id = target.id
  return diffobj;
}

// https://github.com/flitbit/diff
// import DeepDiff from 'deep-diff'
// const applyChange = DeepDiff.applyChange
// const deepDiff = DeepDiff.diff

// export function diff2(oldVal, newVal, requiredFields) {
//   const changes = deepDiff(oldVal, newVal)
//   let emptyObj = {}
//   if(!changes) return {}
//   changes.forEach(function(it) {
//     applyChange(emptyObj, true, it);
//   });
//   return emptyObj

// }
