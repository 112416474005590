import {getLink} from '../linker'

const formatters = {
  // Show id link
  custAccountGridLink: (cellVal, options, rowData) => {
    const ident = rowData.id
    let href = getLink('custAccount', ident)
    return `<a class="showLink" data-id="${ident}" href="${href}" >${cellVal}</a>`
  },
  // Show Customer link
  custAccountCustomerLink: (cellVal, options, rowData) => {
    const ident = rowData.customer?.id
    let href = getLink('customer', ident)
    return `<a class="showLink" data-id="${ident}" href="${href}" >${cellVal}</a>`
  },

}

export default formatters
