import dataApiFactory from '../dataApiFactory'
import RestDataService from '@yakit/core/api/RestDataService'
import { ensurePrefix } from '@yakit/core/utils'

export default function(opts) {
  const ky = opts.ky
  let api = RestDataService({ ...opts, key: 'ar/glJournal' })

  /**
   * Closure to return fetcher based on glBatchId
   */
  const getFetch = (glBatchId) => {
    const pathKey = `ar/glBatch/${glBatchId}/summary`
    return ({ method = 'get', path = '', ...opts }) => {
      path = ensurePrefix(path, '/')
      let fullPath = `${pathKey}${path}`
      return ky(fullPath, { ...opts, method })
    }
  }
  /**
   * Overrides standard search to support ar/glBatch/${glBatchId}/summary syntax
   */
  api.search = async (params) => {
    const fetch = getFetch(params.q.glPostBatchId)

    const searchParams = api.setupQueryParams(params)
    const page =  await fetch({ searchParams: { ...searchParams } }).json()
    return page
  }

  return api
}
