import Logger from '../logger'; let log = Logger(false);
import { get, writable, derived } from 'svelte/store';
import {getService} from '../injector'
import { defaults, _get, extend} from '../objectz';
import type { Writable } from 'svelte/store'
import type { DataApi } from '../api'
import type { ModelStore } from './types'
import type { DataObject } from '../types';
import { isNumeric } from '../is';

// ---end imports--

//status
const IDLE='idle', LOADING='loading', SAVING="saving", ERROR='error', SUCCESS='success', DIRTY='dirty'
export { IDLE, LOADING, SAVING, ERROR, SUCCESS, DIRTY}


/**
 * Makes the model store using key for the dataApi
 * @param o.key - The api key, example: ar.customer
 * @returns
 */
export function makeModelStore({ key }: { key: string }): ModelStore {
  let dataApi = getService('dataApiFactory').get(key)
  return modelStore({ dataApi })
}

/**
 * Resource for dataApi in the spirit of ActiveRecord and angularJs old $resource service.
 * Default is paged view.
 * Used to back any editable grid or list and has stores for
 *  - currentPage: the data for current page of list
 *  - activeModel: the active record/item resource
 *  - selectedIds

 * @param obj - with dataApi
 * @returns {ModelStore} the resource instance
 */
export function modelStore({ dataApi }: { dataApi: DataApi }): ModelStore {

  const apiPath = dataApi.path
  let modelData = undefined
  const model = writable({})
  let idProp = dataApi.idProp
  const BIND_ID = 'bindId'

  /*
    isIdle or `status === 'idle' - default temp state before load, The mutation is currently idle or in a fresh/reset state
    isLoading or status === 'loading' - busy, talking to server to save or load
    isError or status === 'error' - The mutation encountered a validation or server error
    isSuccess or status === 'success' - The last mutation was successful
    isModified or status === 'dirty' - The model has changes
    Beyond those primary state, more information is available depending on the state the mutation:

    error - If the mutation is in an isError state, the error is available via the error property.
  */
  const status = writable(LOADING)

  // const validStatus = writable(IDLE)

  const state = derived( [ status ], ([ $status ]) => {
    return {
      isIdle: $status === IDLE,
      isLoading: $status === LOADING,
      isError: $status === ERROR,
      isSuccess: $status === SUCCESS,
      isModified: $status === DIRTY,
      // isValid: $validStatus === VALID,
      // isValidating: $validStatus === VALIDATING,
    }
  })

  function isSuccess(){
    return get(status) === SUCCESS
  }

  /** loads the item from the server, uses the getId is nothing is passes in.  */
  async function load(_id: any): Promise<DataObject> {
    status.set(LOADING)
    let currentId = _id ?? getId()
    //should not happen but error if no id was set
    if(!currentId) throw new Error("load called without a valid id")
    try {
      const loadedItem = await dataApi.fetchById(currentId)
      log("loadedItem", loadedItem)
      setModel(loadedItem)
      status.set(IDLE)
      return loadedItem as DataObject
    } catch (er) {
      console.error(er)
      status.set(ERROR)
      // handleError(er)
      throw er
    }
  }

  /**
   * update to dataApi and sets model data to results
   */
  async function save({data = modelData, insert = false, params = {}} = {}) {
    if(!insert && !data[idProp]) throw new Error("save update called without id")
    status.set(LOADING)
    try {
      const updatedItem = insert ? await dataApi.create(data, params) : await dataApi.update(data)
      setModel(updatedItem)
      status.set(SUCCESS)
    } catch (er) {
      console.error(er)
      status.set(ERROR)
      // handleError(er)
      throw er
    }
    return modelData
  }

  function getId() {
    return modelData && modelData[idProp] || null
  }

  function setModel(value){
    modelData = value
    model.set(value)
    status.set(IDLE)
  }

  function apiKeyId(){
    return apiPath.replace('/', '_')
  }

  const obj: ModelStore = {
    //store contract
    subscribe: model.subscribe,
    update: model.update,
    set: setModel,
    //status store
    status,
    state,
    getState: ()=>get(state),
    isSuccess,
    model,
    dataApi,
    apiPath,
    apiKeyId,
    get: ()=>modelData,
    getId,
    save,
    load,

    async upsert(data = modelData) {
      if (data[BIND_ID]) {
        let bindId = data[BIND_ID]
        delete data[BIND_ID]
        // If value is numeric then explicitly convert convert to number, buy default we return it as string
        if (isNumeric(bindId)) {
          bindId = parseInt(bindId)
        }
        data[idProp] =  bindId
        return obj.save({data, insert: true, params: {bindId: true}})
      }
      if(data[idProp]) {
        return obj.save({data, insert: false})
      }
      else {
        return obj.save({data, insert: true})
      }
    },

    async delete() {
      let res = await dataApi.remove(getId())
      return res
    }
  }

  return obj
}

// export default ModelStore

