<script>
  import { onDestroy, onMount } from 'svelte'

  function handleCtrlClick(event) {
    if ((event.ctrlKey || event.metaKey) && event.target.tagName === 'A') {
      event.stopImmediatePropagation()
      event.preventDefault()
      const newWindow = window.open(event.target.href, '_blank')
    }
  }

  //When we open in new tab we miss # in url because in grids we specify url without separator
  function addUrlSeparator() {
    const currentUrl = window.location.href
    if (!currentUrl.includes('#/')) {
      const currentPath = window.location.pathname + window.location.search + window.location.hash
      // Construct the new path with hash
      const newPath = `/#${currentPath}`
      // Update the browser's address bar
      history.replaceState(null, null, newPath)
    }
  }

  onMount(() => {
    addUrlSeparator()
    // Attach event listener to the document
    document.addEventListener('click', handleCtrlClick, {capture: true})

    // Cleanup the event listener when the component is destroyed
    return () => {
      document.removeEventListener('click', handleCtrlClick, {capture: true})
    }
  })

</script>
