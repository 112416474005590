import elementResizeDetectorMaker from "element-resize-detector";

let erd = elementResizeDetectorMaker({ strategy: "scroll", callOnAdd: false });

function watchResize(element, handler) {
    erd.listenTo(element, handler);
    let currentHandler = handler;

    return {
        update(newHandler) {
            erd.removeListener(element, currentHandler);
            erd.listenTo(element, newHandler);
            currentHandler = newHandler;
        },
        destroy() {
            erd.removeListener(element, currentHandler);
        },
    };
}

export default watchResize
