<script>
  import { onMount } from 'svelte'
  import { isString } from '@yakit/core/is';
  import { classNames } from './utils';
  import Logger from '@yakit/core/logger'
  import { Icon } from '.'
  let log = Logger(false)

  let className = undefined;
  export { className as class }; //work around since class is reserved

  export let name = ''
  export let letters = ''
  export let charCount = 2
  export let imgSrc = ''
  export let imgName = ''
  export let square = false
  export let imgClass = ''
  export let dot = false
  export let icon = ''

  let lettersClass

  $: clazz = classNames(
    'avatar',
    className,
    {
      'is-squared': square,
      'has-dot': dot,
      'has-dot-squared': (dot && square),
      [`dot-${dot}`]: isString(dot)
    }
  )

  $: if(name && !imgSrc){
    let splitString = name.trim().split(/(?=[A-Z\s])/)
    splitString = splitString.filter(it => it.trim())
    log("avatar from splitString", splitString)
    let _charCount = charCount
    if (_charCount > splitString.length) {
      _charCount = splitString.length
    }
    let _letters = ""
    for (let i = 0; i < _charCount; i++) {
      _letters = _letters + splitString[i].trim().charAt(0)
    }
    letters = _letters.toUpperCase()
    log("avatar letters", letters)
  }

  $: if(letters){
    lettersClass = 'letter-color-' + letters.charAt(0).toLowerCase()
  }


  $: {
    //FIXME hard coded for now, pull baseUrl from config
    if(imgName && !imgSrc) imgSrc = `/assets/images/photos/${imgName}`// getAvatarUrl(imgName)
    if(square) imgClass = `${imgClass} is-squared`
  }

</script>

<span class="{clazz} my-0">
  {#if imgSrc}
    <img class="avatar-img {imgClass}" src="{imgSrc}"  alt="">
  {/if}
  {#if letters}
    <span class="avatar-img is-letter {lettersClass}">
        <span>{letters}</span>
    </span>
  {/if}
  {#if icon}
    <span class="avatar-img is-letter">
      <Icon {icon}/>
    </span>
  {/if}
</span>
