// here for now, RcmAppState intercepts the call and uses thins instead for appState.sidebarConfig
const ipaySidebar = {
  home: {
    i18n: 'dashboard',
    route: 'ipay.home'
  },
  payinvoices: {
    i18n: 'ipay.payinvoices',
    route: 'ipay.payinvoices'
  },
  portaltrans: {
    i18n: "ar.trans",
    route: "ipay.trans"
  },
  wallet: {
    i18n: "ipay.wallet",
    route: "ipay.wallet"
  },
  // payschedule: {
  //   i18n: "ipay.schedule",
  //   route: "ipay.schedule"
  // },
  demo: {
    i18n: "demo",
    routes:[
      { key: 'about.styleGuide', i18n: 'demo.styles' },
      { key: 'about.colors', i18n: 'demo.colors' },
      // { key: ar.custaccounts }
    ]
  }
}

export default ipaySidebar
