<script>
  import Device from 'svelte-system-info'
  import appCtx from '~/injector'
  import {copyTextToClipboard} from '../../../ui/svelte/flextable/support/utils'
  import { Icon, ListItem } from 'framework7-svelte'
  import growl from '@yakit/ui/growl'
  import { onMount } from 'svelte'
  import { makeKy } from '@yakit/core/api'
  import injector from '@yakit/core/injector'

  const apiUrl = injector.get('apiUrl')
  const info = appCtx.get('appState').info
  const data = {
        browser: `${Device.BrowserName}:${Device.BrowserVersion}`,
        os: `${Device.OSName}:${Device.OSVersion}`,
        api: undefined,
        ui: info.version
    }
    const ky = makeKy({ apiConfig: { apiUrl } })
    onMount(async () => {
      const { apiVersion } = await ky.get('appConfig/appVersion').json()
      data.api = apiVersion
    })
    
  const copyInfo = () => {
    copyTextToClipboard(JSON.stringify(data))
    growl.info('Info copied', '')
  }
</script>
<ListItem on:click={copyInfo}>
    <Icon slot="media" material="computer" />
    <div >
        <div>Browser: {data.browser}</div>
        <div>OS: {data.os}</div>
        <div>API: {data.api}</div>
        <div>UI: {data.ui}</div>
    </div>
</ListItem>
