/**
 * Common injector holder that can be imported and used to get a service.
 * Its of course prefered to wire them up but its not always practical and sometimes just need the service reference
 * import {getInjector} from '@yakit/core/injector'
 * then can do getInjector() to retireve the injector that was set here in your app init
 * or getInjector().get('someService')
 * import {getService} from '@yakit/core/injector'
 * can then be used as in getService('someService')
 */
//using a fork for now that support destructuring. see PR under yakworks/didi
import { Injector } from '@yakit/didi'
import { extend } from './objectz'

export const coreModule = {
  'base_url': ['value', 'foo']
}

let injectorInstance //injector holder

function use(injector){
  injectorInstance = injector
}

/**
 * gets the main injector
 */
function getInjector(){ return injectorInstance}

/**
 * gets the service from the injector
 */
function getService(name){ return injectorInstance.get(name)}


/**
 * Creates an injector from passed options.
 *
 * @ignore
 * @param  {Object} options
 * @return {Injector}
 */
function createInjector(options) {

  options = options || {};

  var configModule = {
    'config': [ 'value', options ]
  };

  let modules = [ configModule, coreModule ].concat(options.modules || [])
  injectorInstance = new Injector(modules)
  return injectorInstance
}

export { createInjector, getInjector, getService, use }

/**
 * @param {Injector} [_injector] an (optional) injector to bootstrap the diagram with
 */
export function InjectorSingleton(_injector?: Injector)  {
  // _injector = _injector || {}

  this.modules = (mods) =>{
    injectorInstance = new Injector(mods)
    extend(this, injectorInstance)
    // this.injector = injectorInstance
    // this.get = injectorInstance.get
    // this.invoke = injectorInstance.invoke
    // this.init = injectorInstance.init
    // this.createChild = injectorInstance.createChild
    return this
  }
  if(_injector){
    // create injector unless explicitly specified
    this.injector = _injector
    this.injector = injectorInstance
    this.get = injectorInstance.get
    this.invoke = injectorInstance.invoke
    this.init = injectorInstance.init
    this.createChild = injectorInstance.createChild
  }

}

const injectorSingleton = new InjectorSingleton()

export default injectorSingleton as Injector
