import arTranListFormatters from './ar'
import tranListFormatters from './tran'
import custListFormatters from './customer'
import custAccountListFormatters from './custAccount'
import paymentListFormatters from './autocash'
import adjustLine from './adjustLine'

let gridFormatters = {
  ...tranListFormatters,
  ...custListFormatters,
  ...custAccountListFormatters,
  ...arTranListFormatters,
  ...paymentListFormatters,
  ...adjustLine
}

//FIXME move this somewhere
if(window.$ !== undefined){
  $.extend($.fn.fmatter, gridFormatters)
}

export default gridFormatters
