<script>
  import Logger from '@yakit/core/logger'; const log = Logger(false)
  import { f7,f7ready, theme, Button, View} from 'framework7-svelte';
  import { onMount } from 'svelte';
  import {get, writable} from 'svelte/store';
  import {opened, overlay} from './sidebarState'
  import routesHolder from '../routes/holder';

  // export let opened = writable(true)
  // export let overlay = writable(false)
  export let mainRouter = undefined

  let isViewInit = routesHolder.isViewReady

  $: {
    toggleSidebarClasses($opened)
  }

  function toggleSidebarClasses(_){
    let _html = document.body.parentElement
    if($opened){
      _html.classList.add("sidebar-open")
      _html.classList.remove("sidebar-closed")
    } else{
      _html.classList.remove("sidebar-open")
      _html.classList.add("sidebar-closed")
    }
  }

  let currentRoute

  // listen to highlight active item
  $: if(mainRouter){
    currentRoute = mainRouter.currentRoute
    mainRouter.on('routeChange', (route) => {
      log("route changed")
      currentRoute = route
      // highlightActive()
    });
  }

  $: if(currentRoute){

    highlightActive()
  }

  function highlightActive(){
    if(!currentRoute.url) return
    const $el = f7.$('.page-sidebar');
    const url = currentRoute.url;
    if (!$el) return;
    const $linkEl = $el.find(`a[href="${url}"]`);
    if (!$linkEl.length) return;
    $el.find('.item-selected').removeClass('item-selected');
    $linkEl.addClass('item-selected');
  }

  const onResize = () => {
    if (f7.width >= 1024) {
      // $el.find('.list:not(.searchbar-not-found)').addClass('menu-list');
      $opened = true
      $overlay = false
    } else {
      // $el.find('.list:not(.searchbar-not-found)').removeClass('menu-list');
      $opened = false
      $overlay = true
    }
  };

  $: if($isViewInit){
    log("Sidebar isViewInit set")
    mainRouter = f7.views.main.router
    if (theme.aurora) {
      // fire it right away
      onResize();
      //then listen
      f7.on('resize', onResize);
      // currentRoute = mainRouter.getCurrentRoute()
    }
  }
  // onMount(() => {

  //   f7ready(() => {
  //     mainRouter = f7.views.main.router
  //     if (theme.aurora) {
  //       // fire it right away
  //       onResize();
  //       //then listen
  //       f7.on('resize', onResize);
  //       // currentRoute = mainRouter.getCurrentRoute()
  //     }
  //   })
  // });
</script>

<aside class="sidebar" class:overlay={$overlay}>
  <div class="burger">
    <Button iconF7="sidebar_left" on:click={ () => $opened = !$opened}/>
  </div>

  <View url="/sidebar-menu/" linksView=".view-main" />
</aside>

{#if $overlay && $opened}
  <div class="sidebar-backdrop" on:click={()=> $opened=false}></div>
{/if}

<style>

:global(aside.sidebar) {
  --sidebar-width: 256px:
}
aside.sidebar{
  --f7-safe-area-right: 0px;
  --f7-safe-area-outer-right: 0px;
  z-index: 5002;
  flex: 0 0 var(--sidebar-width);
  transition: margin 0.3s ease;
  /* overflow-y: auto; */
  margin-left: -256px;
  width: 256px;
  border-right: var(--f7-page-master-border-width) solid var(--f7-page-master-border-color);
  position: static;
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  height: 100%;
}

aside.sidebar.overlay{
  position: absolute;
}

:global(.sidebar-open) aside.sidebar {
  margin-left: 0;
}

.burger :global(.button) {
  top: 0 !important;
  position: fixed;
  top: 1em;
  left: 4px;
  z-index: 10000;
  height: calc(var(--f7-navbar-height) + var(--f7-safe-area-top));
  /* height: var(--f7-navbar-link-height); */
}

/* make space for the burger */
:global(.sidebar-closed .navbar-inner) {
  padding-left: 52px;
}

.sidebar-backdrop {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 5001;
  visibility: visible;
  opacity: 1;
  transition-duration: 400ms;
}
</style>
