/**
 * user is used often enough, this is simple store that can just be imported wihtout
 * going through appState or the injector
 */
import {get, writable} from 'svelte/store';
import type { Writable } from 'svelte/store'
// import Logger from '@yakit/core/logger'
// let log = Logger(false)

export interface User {
  id: number;
  orgId: number;
  username: string;
  isCustomer: boolean;
}

//add props to placeholder so vscode doesnt show red
let emptyUser:User = { id: undefined, orgId: undefined, username: undefined, isCustomer: false }
export const user: Writable<User> = writable(emptyUser)

export const custId = writable(0)

//short cut functions to get when outside of svelte and cant do $ shortcut
export function getUser(){
  return get(user)
}

//short cut functions to get when outside of svelte and cant do $ shortcut
export function getCustId(){
  return get(custId)
}

