import Logger from '@yakit/core/logger'; let log = Logger(false);
import { get as sget, } from 'svelte/store';
import { get, merge, defaults } from '@yakit/core/objectz'
import {t, json} from '../../../i18n'
import { makeLabel } from '@yakit/core/utils/nameUtils';

// the functions from stores that get set on first call
let $t, $json

/**
 * Gets schema meta from server and configures column defaults.
 */
async function setupColumns({ resource, columns, msgKeyPrefix }) {
  $t = sget(t)
  $json = sget(json)
  // const msgPrefix = resource.apiPath.replace('/', '.')
  log("msgKeyPrefix", msgKeyPrefix)
  let meta = await resource.getMeta()
  log("meta", meta)
  let mergedColumns = columns.map(column => {
    let metaCol = get(meta, column.id || column.name)
    if(metaCol) mergeColumn(column, metaCol)
    i18Name(column, msgKeyPrefix)
    return column
  })

  log("mergedColumns", mergedColumns)
  return mergedColumns //Object.assign(ctrl, opts)
}

function mergeColumn(column, metaCol){
  const {type, format} = metaCol
  let toMerge = {}
  if(type === 'number' || type === 'integer'){
    toMerge.align = 'right'
  } else {
    toMerge.align = 'left'
  }
  merge(toMerge, {type, format}) //only adds type and format if they are not nil
  defaults(column, toMerge) //defaults only adds to columns if props dont exist already
  return column
}

/**
 * if column.name is already set then does nothing.
 * otheriwse looks up id for a name title. tries the keyPrefix.id first then just looks up column id.
 *
 * @param {*} column the col
 * @param {*} keyPrefix the key prefix to use first
 * @returns the column ref
 */
function i18Name(column, keyPrefix){
  if(!column.name) {
    let id = column.id
    log("base key", $json(`${keyPrefix}`))
    let msgKeys = [`${keyPrefix}.${column.id}`, id]
    // "cust.name" 👉️ "name"
    if(id.lastIndexOf('.') !== -1 ) msgKeys.push( id.slice(0,  id.lastIndexOf('.')) )
    for (const msgKey of msgKeys) {

      if($json(msgKey)){
        log("found msgkey", msgKey)
        column.name = $t(msgKey)
        break //break on first match
      }
    }
    //if still not set then default to id
    if(!column.name) column.name = makeLabel(column.id)
  }
  return column
}

export default setupColumns
