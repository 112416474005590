// import { Injector } from 'didi'
import injector from '@yakit/core/injector'
import appModule from './module'
import autocash from './autocash/module'
import ar from './ar/module'

const _injector = injector.modules([
  appModule,
  autocash,
  ar
])

//wire the coreInjector, TODO there has to be a cleaner way but this is how we expose it to core and ui
// coreInjector.use(injector)
// console.log("coreInjector set")
export default _injector

