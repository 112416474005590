import { addMessages, init, getLocaleFromNavigator, register} from '@yakit/ui/i18n';

import en from './en.json';
import fields_en from './fields_en.yaml';
addMessages('en', en);
addMessages('en', fields_en);

// import enUS from './en-US.json';
// import pt from './pt.json';

// addMessages('en', en);
// addMessages('en-US', enUS);
// addMessages('pt', pt);
// register('en', () => import('./en.json'));
// register('en', () => import('./fields_en.json'));

init({
  fallbackLocale: 'en',
  initialLocale: getLocaleFromNavigator(),
});
